import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Farcaster = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.12451 2.48901H11.8756V13.5112H10.7378V8.46234H10.7267C10.6009 7.06695 9.42819 5.97345 8.00006 5.97345C6.57194 5.97345 5.3992 7.06695 5.27344 8.46234H5.26229V13.5112H4.12451V2.48901Z" />
      <path d="M2.06201 4.05322L2.52424 5.61767H2.91534V11.9466C2.71897 11.9466 2.55979 12.1058 2.55979 12.3021V12.7288H2.48868C2.29232 12.7288 2.13312 12.888 2.13312 13.0843V13.511H6.11534V13.0843C6.11534 12.888 5.95616 12.7288 5.75979 12.7288H5.68868V12.3021C5.68868 12.1058 5.52948 11.9466 5.33312 11.9466H4.90646V4.05322H2.06201Z" />
      <path d="M10.8091 11.9466C10.6127 11.9466 10.4535 12.1058 10.4535 12.3021V12.7288H10.3824C10.186 12.7288 10.0269 12.888 10.0269 13.0843V13.511H14.0091V13.0843C14.0091 12.888 13.8499 12.7288 13.6535 12.7288H13.5824V12.3021C13.5824 12.1058 13.4232 11.9466 13.2269 11.9466V5.61767H13.618L14.0802 4.05322H11.2357V11.9466H10.8091Z" />
    </svg>
  );
};
