import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Instagram = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 3.0815C9.602 3.0815 9.792 3.0875 10.425 3.1165C12.051 3.1905 12.8105 3.962 12.8845 5.576C12.9135 6.2085 12.919 6.3985 12.919 8.0005C12.919 9.603 12.913 9.7925 12.8845 10.425C12.81 12.0375 12.0525 12.8105 10.425 12.8845C9.792 12.9135 9.603 12.9195 8 12.9195C6.398 12.9195 6.208 12.9135 5.5755 12.8845C3.9455 12.81 3.19 12.035 3.116 10.4245C3.087 9.792 3.081 9.6025 3.081 8C3.081 6.398 3.0875 6.2085 3.116 5.5755C3.1905 3.962 3.948 3.19 5.5755 3.116C6.2085 3.0875 6.398 3.0815 8 3.0815ZM8 2C6.3705 2 6.1665 2.007 5.5265 2.036C3.3475 2.136 2.1365 3.345 2.0365 5.526C2.007 6.1665 2 6.3705 2 8C2 9.6295 2.007 9.834 2.036 10.474C2.136 12.653 3.345 13.864 5.526 13.964C6.1665 13.993 6.3705 14 8 14C9.6295 14 9.834 13.993 10.474 13.964C12.651 13.864 13.865 12.655 13.9635 10.474C13.993 9.834 14 9.6295 14 8C14 6.3705 13.993 6.1665 13.964 5.5265C13.866 3.3495 12.6555 2.1365 10.4745 2.0365C9.834 2.007 9.6295 2 8 2V2ZM8 4.919C6.2985 4.919 4.919 6.2985 4.919 8C4.919 9.7015 6.2985 11.0815 8 11.0815C9.7015 11.0815 11.081 9.702 11.081 8C11.081 6.2985 9.7015 4.919 8 4.919ZM8 10C6.8955 10 6 9.105 6 8C6 6.8955 6.8955 6 8 6C9.1045 6 10 6.8955 10 8C10 9.105 9.1045 10 8 10ZM11.203 4.0775C10.805 4.0775 10.4825 4.4 10.4825 4.7975C10.4825 5.195 10.805 5.5175 11.203 5.5175C11.6005 5.5175 11.9225 5.195 11.9225 4.7975C11.9225 4.4 11.6005 4.0775 11.203 4.0775Z" />
    </svg>
  );
};
