import Link from "next/link";
import { useEffect, useState } from "react";

import SimpleButton from "@/client/components/common/Button";
import { CardCarousel } from "@/client/components/common/CardCarousel";
import Loading from "@/client/components/common/Loading";
import { SectionTitle } from "@/client/components/common/SectionTitle";
import { listActivity } from "@/client/lib/api";
import { Activity } from "@/shared/types/activity";

const LIMIT = 12;

/**
 * Shows recent sales activity
 */
export const RecentSaleActivity = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>();

  useEffect(() => {
    fetchActivity();
  }, []);

  const fetchActivity = async () => {
    setLoading(true);
    try {
      const result = await listActivity({
        limit: LIMIT,
        filters: { kind: "sale" },
      });
      setActivities(result);
      setLoading(false);
    } catch (err) {
      console.error("failed to fetch recent sale activity", err);
    }
  };

  return (
    <div>
      <div className="sm:mb-8 flex items-center justify-between">
        <div>
          <SectionTitle title="Just Collected" />
          <p className="mt-2 text-neutral-500 dark:text-neutral-400">
            See what other collectors have recently bought
          </p>
        </div>

        <div className="hidden sm:block">
          <SimpleButton
            as="Link"
            href={"/activity"}
            variant="outline"
            className="px-4 flex-shrink-0 py-3"
          >
            View all
          </SimpleButton>
        </div>
      </div>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <CardCarousel data={activities} cardType="sale" />
        )}
      </div>
      <span className="sm:hidden mt-8 block">
        <SimpleButton
          title="View activity"
          variant="outline"
          className="px-4 flex-shrink-0 py-3"
          as="Link"
          href={"/activity"}
        >
          View all
        </SimpleButton>
      </span>
    </div>
  );
};

export default RecentSaleActivity;
