import React from "react";
import { Collection } from "@/shared/types/collection";
import Button from "../frame-design-system/buttons/Button";
import { MintState } from "@/shared/types/collection";
import Countdown from "react-countdown";
import {
  getPlatformColorGradient,
  getArtBlocksPlatformLockup,
} from "@/shared/artblocks";
import { isArtBlocksPlatform } from "@/shared/platforms";

type CollectionData = {
  collection: Collection;
  isRounded?: boolean;
};

const countDownRenderer = ({ days, hours, minutes }: any) => {
  // Render a countdown
  return (
    <span>
      {days}d {hours}h {minutes}m
    </span>
  );
};

const PlatformBadgePill = ({ collection }: CollectionData) => {
  const { platformData } = collection;
  if (!collection || !isArtBlocksPlatform(platformData)) return null;

  const backgroundGradient = getPlatformColorGradient(platformData.kind);
  const gradientStyle = {
    background: `linear-gradient(90deg, rgb(${backgroundGradient?.from}), rgb(${backgroundGradient?.to})`,
  };
  const lockupImageUrl = getArtBlocksPlatformLockup(platformData.kind);

  return (
    <div
      style={gradientStyle}
      className="flex items-center p-2 space-x-2 rounded-full"
    >
      <img src={lockupImageUrl} width={70} />
    </div>
  );
};

const DetailsSubheader = ({ collection, isRounded }: CollectionData) => {
  const { mintStartsAt, mintState, supplyMax, stats, name, artistName } =
    collection;
  let collectionDetails;
  let buttonText;

  if (mintState === MintState.Upcoming) {
    collectionDetails = "Opens in ";
    buttonText = "Explore";
  } else if (mintState === MintState.InProgress) {
    collectionDetails = `${stats?.supplyTotal || 0} / ${supplyMax || "--"} Minted`;
    buttonText = "Purchase";
  } else {
    collectionDetails = "";
    buttonText = "View listings";
  }

  return (
    <div className="relative h-[150px] sm:h-[100px] bg-white/70 dark:bg-neutral-900/70 background-blur">
      <div
        className={`absolute inset-0 flex flex-col sm:flex-row sm:justify-between items-start z-10 p-4 sm:px-10 ${
          isRounded ? "rounded-b-xl" : ""
        }`}
      >
        <div>
          <h2 className="text-4xl font-medium tracking-tight dark:text-white">
            {name}
          </h2>
          <p className="text-neutral-600 dark:text-neutral-300">
            By {artistName}
          </p>
        </div>
        <div className="flex flex-row-reverse mt-3 sm:flex-row">
          <div className="flex items-center ml-4 space-x-1 sm:ml-0 sm:mr-4">
            <p className="text-p-xs text-neutral-600 dark:text-neutral-300">
              {collectionDetails}
            </p>
            {mintState === MintState.Upcoming ? (
              <p className="pr-2 text-p-xs text-neutral-600 dark:text-neutral-300">
                <Countdown date={mintStartsAt} renderer={countDownRenderer} />
              </p>
            ) : null}
          </div>

          <Button
            as="Link"
            type="primary"
            href={`/collections/${collection?.slug || collection?._id}`}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const CollectionFeatureCard = ({
  collection,
  isRounded = false,
}: CollectionData) => {
  if (!collection) return null;
  const { imageUrl } = collection;
  return (
    <section
      className={`flex flex-col bg-center relative h-[550px] ${isRounded ? "rounded-xl" : ""}`}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute top-10 right-10">
        <PlatformBadgePill collection={collection} />
      </div>
      <div style={{ flex: 1 }}></div>
      <DetailsSubheader collection={collection} isRounded={isRounded} />
    </section>
  );
};
