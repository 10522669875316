import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Youtube = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.0767 2.12275C10.674 1.95875 5.32267 1.95942 2.92333 2.12275C0.325333 2.30008 0.0193333 3.86942 0 8.00008C0.0193333 12.1234 0.322667 13.6994 2.92333 13.8774C5.32333 14.0408 10.674 14.0414 13.0767 13.8774C15.6747 13.7001 15.9807 12.1308 16 8.00008C15.9807 3.87675 15.6773 2.30075 13.0767 2.12275ZM6 10.6667V5.33342L11.3333 7.99542L6 10.6667Z" />
    </svg>
  );
};
