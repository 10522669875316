import CollectionCard from "@/client/components/frame-design-system/cards/CollectionCard";
import { SectionHeader } from "@/client/components/frame-design-system/text/SectionHeader";
import { Collection } from "@/shared/types/collection";

export const CollectionCardSection = ({
  title,
  subtitle,
  collections,
  showPlatformBadge,
  navElementText,
  navElementLink,
}: {
  title: string;
  subtitle: string;
  collections: Collection[];
  showPlatformBadge?: boolean;
  navElementText?: string;
  navElementLink?: string;
}) => {
  return (
    <div className="space-y-8">
      <SectionHeader
        title={title}
        subtitle={subtitle}
        navElementText={navElementText}
        navElementLink={navElementLink}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
        {collections?.map((collection: any) => {
          return (
            <CollectionCard
              key={collection?._id}
              collection={collection}
              showPlatformBadge={showPlatformBadge}
            />
          );
        })}
      </div>
    </div>
  );
};
