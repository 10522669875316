import { cn } from "@/client/lib/classnames";
import { getBasePath } from "@/shared/config";
import { PlatformData, isArtBlocksPlatform } from "@/shared/platforms";
import Button from "@/client/components/frame-design-system/buttons/Button";

type PlatformInlineBannerProps = {
  platform: PlatformData;
  headingText: string;
  subHeadingText: string;
  buttonText: string;
};

export const PlatformInlineBanner = ({
  platform,
  headingText,
  subHeadingText,
  buttonText,
}: PlatformInlineBannerProps) => {
  const isArtBlocks = isArtBlocksPlatform(platform);
  if (!platform) return null;

  return (
    <section
      className="relative flex flex-col p-10 overflow-hidden rounded-2xl"
      style={{ backgroundColor: platform.platformColor || "#D9E4E6" }}
    >
      <div className={`absolute top-0 right-0 w-full h-full]`}>
        <CurvedGreenVector className="absolute right-0 w-[60%] object-cover" />
      </div>

      <div className="z-10">
        {isArtBlocks && (
          <img
            src={`${getBasePath()}/ab-wordmark.svg`}
            className="h-5 mb-1"
            alt="Art Blocks header text"
          />
        )}
        <h2
          className={cn(
            "font-[Sohne] font-medium text-display-l leading-none mb-2 inline-block dark:text-black"
          )}
        >
          {headingText}
        </h2>
      </div>
      <div className="z-10 flex flex-col justify-between gap-8 sm:flex-row sm:gap-0 sm:items-center">
        <p>{subHeadingText}</p>

        <Button
          title={buttonText}
          as="Link"
          href={`/category/${platform.slug}`}
          darkModeDisabled
        >
          {buttonText}
        </Button>
      </div>
    </section>
  );
};

const CurvedGreenVector = ({ ...props }) => {
  return (
    <svg
      width="718"
      height="249"
      viewBox="0 0 718 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M171.462 152.022L12.6052 336.963C4.47188 346.432 0 358.501 0 370.984C0 399.427 22.7668 422.637 51.2047 423.186L915.949 439.87C1033.49 442.138 1130 347.474 1130 229.909V98.3425C1130 2.09311 1064.57 -81.8353 971.226 -105.314L947.018 -111.403C854.304 -134.724 757.523 -92.4494 711.596 -8.60191C670.111 67.1334 586.48 109.78 500.822 98.8504L357.342 80.5432C287.306 71.6068 217.466 98.4628 171.462 152.022Z"
        fill="url(#paint0_linear_392_98795)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_392_98795"
          x1="0"
          y1="146.5"
          x2="1130"
          y2="146.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E1FF" />
          <stop offset="1" stopColor="#00F500" />
        </linearGradient>
      </defs>
    </svg>
  );
};
