import { getPlatformDataByKind, Platform } from "@/shared/platforms";
import { PlatformInlineBanner } from "./PlatformInlineBanner";

export const StudioBanner = () => {
  const abStudio = getPlatformDataByKind(Platform.ArtBlocksStudio);

  return (
    <PlatformInlineBanner
      platform={abStudio}
      headingText="Studio"
      subHeadingText="Self-published artworks by leading generative artists"
      buttonText="Explore"
    />
  );
};
