import { useEffect, useState } from "react";

import { CardCarousel } from "@/client/components/common/CardCarousel";
import Loading from "@/client/components/common/Loading";
import { listActivity } from "@/client/lib/api";
import { Activity } from "@/shared/types/activity";

import { SectionTitle } from "../../common/SectionTitle";

const LIMIT = 12;

/**
 * Shows recent sales activity for notable collectors
 */
export const RecentCollectorSales = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>();

  useEffect(() => {
    fetchActivity();
  }, []);

  const fetchActivity = async () => {
    setLoading(true);
    try {
      const result = await listActivity({
        limit: LIMIT,
        filters: { kind: "sale", onlyNotableAddresses: true },
      });
      setActivities(result);
      setLoading(false);
    } catch (err) {
      console.error("failed to fetch recent listing activity", err);
    }
  };

  return (
    <div className="mb-24">
      <div className="mb-8">
        <SectionTitle title="Notable Acquisitions" />
        <p className="mt-2 text-neutral-500 dark:text-neutral-400">
          Artworks recently purchased by notable collectors
        </p>
      </div>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <CardCarousel data={activities} cardType="notableCollector" />
        )}
      </div>
    </div>
  );
};

export default RecentCollectorSales;
