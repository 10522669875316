import Link from "next/link";
import { Discord } from "../icons/discord";
import { XLogo } from "../icons/x-logo";
import { Farcaster } from "../icons/farcaster";
import { Youtube } from "../icons/youtube";
import { Instagram } from "../icons/instagram";
import { getBasePath } from "@/shared/config";
import { getPrivacyPolicyUrl, getTermsOfServiceUrl } from "@/client/lib/links";

const Footer = () => {
  return (
    <footer className="py-12 px-8 flex justify-between items-center border-t border-neutral-150 dark:border-neutral-700">
      <div className="flex flex-col gap-2">
        <Link href="/">
          <img
            src={`${getBasePath()}/logo-text.svg`}
            className="cursor-pointer hover:text-neutral-600 h-4 dark:hidden font-medium mr-8"
          />
          <img
            src={`${getBasePath()}/logo-text-white.svg`}
            className="cursor-pointer hover:text-neutral-600 h-4 hidden dark:block font-medium mr-8"
          />
        </Link>
        <p className="text-neutral-500 text-sm dark:text-neutral-300">
          The platform for generative content
        </p>
        <div className="flex items-center mt-10 sm:mt-6 gap-6">
          <div>
            <a
              href="https://x.com/artblocks_io"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <XLogo className="h-8 w-8 sm:w-6 sm:h-6 flex-shrink-0 text-neutral-700 dark:text-white" />
            </a>
          </div>
          <div>
            <a
              href="https://discord.com/invite/artblocks"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <Discord className="h-8 w-8 sm:w-6 sm:h-6 flex-shrink-0 text-neutral-700 dark:text-white" />
            </a>
          </div>
          <div>
            <a
              href="https://warpcast.com/artblocks"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <Farcaster className="h-8 w-8 sm:w-6 sm:h-6 flex-shrink-0 text-neutral-700 dark:text-white" />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/channel/UCJ3do9nWP6qaJkcbxZaJZ4w"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <Youtube className="h-8 w-8 sm:w-6 sm:h-6 flex-shrink-0 text-neutral-700 dark:text-white" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/artblocks_io/"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 transition cursor-pointer"
            >
              <Instagram className="h-8 w-8 sm:w-6 sm:h-6 flex-shrink-0 text-neutral-700 dark:text-white" />
            </a>
          </div>
        </div>
        <div className="flex items-center mt-5 gap-6 text-neutral-500 dark:text-neutral-300 text-xs">
          <a href={getPrivacyPolicyUrl()}>Privacy Policy</a>
          <a href={getTermsOfServiceUrl()}>Terms of Service</a>
          <a href={`${getBasePath()}/sitemap.xml`}>Sitemap</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
